<template>
    <b-row class="mb-3 mt-3">
        <b-card class="shadow-sm border-0 rounded-0 px-md-4" bg-variant="light" no-header>
            <b-card-text>
                <h6 class="page-title pb-2 mt-3 text-uppercase">
                    {{ $t("events-filter.title") }}
                </h6>
                <b-row class="mb-3">
                    <b-col md="2" class="pe-md-4">
                        <b-form-group class="mb-3">
                            <label class="form-title">
                                Tipo de usuario
                            </label>
                            <b-input-group>
                                <select class="form-select rounded-0" v-model="selectedUserType"
                                    @change="userTypeHandler">
                                    <option key="resident-agent" value="0">
                                        Agente Residente
                                    </option>
                                    <option key="superintendence" value="1">
                                        Superintendente
                                    </option>
                                    <!-- <option key="official" value="2">
                                        Oficial
                                    </option> -->
                                    <option key="natural-person" value="3">
                                        Persona Natural
                                    </option>
                                    <option key="legal-person" value="4">
                                        Persona Jurídica
                                    </option>
                                    <option key="competent-authority" value="2">
                                        Autoridad Competente
                                    </option>
                                </select>
                                <b-icon-x-circle variant="danger" class="m-2" @click="cleanUserType" />
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row class="mb-3">
                    <b-col md="3" class="pe-md-4">
                        <b-form-group>
                            <label class="form-title">{{
                        $t("events-filter.event-type")
                    }}</label>

                            <b-input-group class="mb-3">
                                <select class="form-select rounded-0" v-model="eventType">
                                    <option v-for="type in filteredEventTypes" :key="type.value" :value="type.value">
                                        {{ $t(`event-type.${type.key}`) }}
                                    </option>
                                </select>
                                <b-icon-x-circle variant="danger" class="m-2" @click="eventType = null" />

                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="3" class="pe-md-4">
                        <b-form-group>
                            <label class="form-title">
                                {{ $t("events-filter.user") }}
                            </label>
                            <b-input-group class="mb-3">
                                <v-select style="width: 85%; border-radius: 0px !important; padding: 1px"
                                    v-model="selectedUser" :options="userOptions" :reduce="opt => opt.value"
                                    :clearable="true" placeholder="Buscar usuario..." />
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="2" class="pe-md-4">
                        <b-form-group>
                            <label class="form-title">
                                Id session
                            </label>
                            <b-form-input v-model="sessionId" size="md" class="rounded-0"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="2" class="pe-md-4">
                        <b-form-group>
                            <label class="form-title">
                                {{ $t("general.identification") }}
                            </label>
                            <b-form-input v-model="identification" size="md" class="rounded-0"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col v-show="selectedUserType === '3'" md="2" class="pe-md-4">
                        <b-form-group>
                            <label class="form-title">
                                {{ $t("natural-person-builder.idoneidad") }}
                            </label>
                            <b-form-input v-model="idoneidad" size="md" class="rounded-0"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="2" class="pe-md-4">
                        <b-form-group>
                            <label class="form-title">
                                {{ $t("legal-person-builder.folio") }}
                            </label>
                            <b-form-input v-model="folio" size="md" class="rounded-0"></b-form-input>
                        </b-form-group>
                    </b-col>

                </b-row>
                <b-row class="mb-3">
                    <b-col md="2" class="pe-md-4">
                        <b-form-group>
                            <label class="form-title">{{
                        $t("events-filter.start-date")
                    }}</label>
                            <b-input-group class="mb-3 rounded-0" size="lg">
                                <DatePicker v-model="startDate" />
                                <b-icon-x-circle variant="danger" class="m-2" @click="startDate = null" />
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="2" class="pe-md-4">
                        <b-form-group>
                            <label class="form-title">{{
                        $t("events-filter.end-date")
                    }}</label>
                            <b-input-group class="mb-3 rounded-0 " size="lg">
                                <DatePicker v-model="endDate" />
                                <b-icon-x-circle variant="danger" class="m-2" @click="endDate = null" />
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-end">
                        <b-button :disabled="!canFilter" class="main-btn" @click="filter">
                            {{ $t("events-filter.filter") }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-card-text>
        </b-card>
    </b-row>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import { eventTypes } from "@/config/enums";
import moment from "moment";
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import DatePicker from "@/components/widgets/DatePicker.vue";

export default {
    name: "events-filters",
    components: {
        DatePicker,
        vSelect // Importamos el componente v-select
    },
    data() {
        return {
            eventTypes: eventTypes,
            eventType: null,
            startDate: new Date(),
            endDate: new Date(),
            selectedUser: null,
            sessionId: null,
            selectedUserType: null,
            users: [],
            sessions: [],
            filteredEventTypes: [],
            idoneidad: null,
            folio: null,
            identification: null
        };
    },
    async mounted() {
        this.users = await this.retrieveUsers(this.selectedUserType);
        this.sessions = await this.retrieveSessions();
        this.filteredEventTypes = this.getEvents(this.selectedUserType);

        this.endDate = new Date(this.startDate);
        this.endDate.setFullYear(this.endDate.getFullYear() + 1);
    },
    watch: {
        startDate(newStartDate) {
            this.endDate = new Date(newStartDate);
            this.endDate.setFullYear(this.endDate.getFullYear() + 1);
        }
    },
    computed: {
        canFilter() {
            return this.eventType !== null ||
                this.selectedUser !== null ||
                this.sessionId != null ||
                (this.startDate !== null && this.endDate !== null) ||
                this.idoneidad !== null ||
                this.folio !== null
        },
        // Calculamos las opciones de usuario para el v-select
        userOptions() {
            return this.users.map(user => ({
                label: user.displayText,
                value: user.id
            }));
        },
        sessionOptions() {
            return this.sessions.map(s => ({
                label: s,
                value: s
            }));
        }
    },
    methods: {
        filter() {
            let startDate =
                this.startDate === null
                    ? this.startDate
                    : this.getUtcDate(this.startDate);
            let endDate =
                this.endDate === null
                    ? this.endDate
                    : this.getUtcDate(this.endDate);

            let filters = {
                userId: this.selectedUser,
                userType: this.selectedUserType,
                eventType: this.eventType,
                startDate: startDate,
                endDate: endDate,
                folio: this.folio,
                identification: this.identification,
                idoneidad: this.idoneidad,
                sessionId: this.sessionId
            };
            this.$emit("filter", filters);
        },
        getUtcDate(date) {
            if (date !== null) {
                let epoch = moment(date).valueOf() / 1000;
                let result = moment.unix(epoch).format("yyyy-MM-DD HH:mm:ss");
                return result;
            }
            return date;
        },
        async retrieveUsers(userType) {
            try {
                let response = await RepositoryFactory.event.getAllUsers({
                    userType: userType
                });
                let users = response.data;
                users = this.sortUsers(users);
                users = users.map(this.formatUser);
                return users
            } catch (error) {
                window.console.log(error);
            }
        },
        async retrieveSessions() {
            try {
                let response = await RepositoryFactory.event.getSessions();
                return response.data.filter(n => n !== null);
            } catch (error) {
                window.console.log(error);
            }
        },
        formatUser(user) {
            return {
                id: user.id,
                displayText: `${user.name} - ${user.email}`,
            };
        },
        sortUsers(users) {
            users.sort((a, b) => {
                if (a.name >= b.name) {
                    return 1;
                } else {
                    return -1;
                }
            });
            return users;
        },
        async cleanUserType() {
            this.selectedUserType = null;
            this.filteredEventTypes = this.getEvents(this.selectedUserType)
            this.users = await this.retrieveUsers(this.selectedUserType);
        },
        getEvents(userType) {
            let events = [...this.eventTypes.filter((e) => e.value !== 503 || e.value !== 404 || e.value !== 1001)];
            const result = userType ? events.filter((a) => {
                return a.userTypes.includes(parseInt(userType)) ? a : null
            }) : events
            return this.sortedItems(result)
        },
        sortedItems(events) {
            let sortedArray = events;
            sortedArray.sort((a, b) => {
                let comparison = 0;
                if (this.$t(`event-type.${a.key}`) < this.$t(`event-type.${b.key}`)) {
                    comparison = -1;
                } else if (this.$t(`event-type.${a.key}`) > this.$t(`event-type.${b.key}`)) {
                    comparison = 1;
                }
                return comparison;
            });

            return sortedArray;
        },
        async userTypeHandler() {
            this.filteredEventTypes = this.getEvents(this.selectedUserType)
            this.users = await this.retrieveUsers(this.selectedUserType)
        },
    },
};
</script>

<style scoped>
.custom-user-select {
    overflow-wrap: anywhere;
}

.vs__dropdown-toggle {
    border: 1px solid #ced4da;
    border-radius: 0;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out;
    white-space: nowrap;
    /* Evita que el texto se desborde y permanezca en una línea */
    overflow-x: auto;
    /* Permitir desplazamiento horizontal */
    overflow-y: hidden;
    /* Ocultar cualquier desplazamiento vertical */
}

.vs__selected {
    color: #495057;
    padding: 0.375rem 0.75rem;
    white-space: nowrap;
    /* Evita que el texto se desborde y permanezca en una línea */
    overflow-x: auto;
    /* Permitir desplazamiento horizontal */
    overflow-y: hidden;
    /* Ocultar cualquier desplazamiento vertical */
}
</style>
