<template>
    <div class="mt-3 px-xl-5">
        <b-row>
            <h6 class="page-title pb-2 mt-3 text-uppercase">
                {{ $t("events.title") }}
            </h6>
            <h6>
                <small class="text-muted"> {{ $t("events.subtitle") }}. </small>
            </h6>
        </b-row>
        <EventsFilters @filter="filter" />
        <div v-if="filters !== null">
            <b-row>
                <b-col>
                    <b-card no-header no-body class="rounded-0 shadow-sm border-0">
                        <b-table ref="eventsTable" stacked="md" small hover select-mode="single" selectable show-empty
                            empty-text="No hay eventos que mostrar" :busy.sync="isBusy" :fields="fields"
                            :items="retrieveData" :current-page="currentPage" :per-page="take">
                            <template #table-busy>
                                <div class="text-center text-primary my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                </div>
                                <div class="text-center text-primary my-2">
                                    <strong>Cargando eventos</strong>
                                </div>
                            </template>
                        </b-table>
                    </b-card>
                </b-col>
            </b-row>
            <b-row class="mt-4 mb-5">
                <b-col>
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="take" size="sm"
                        class="my-0 justify-content-end">
                        <template #first-text>
                            <span><b-icon-chevron-double-left scale="1.3"></b-icon-chevron-double-left></span>
                        </template>
                        <template #prev-text>
                            <span><b-icon-chevron-left scale="1.3"></b-icon-chevron-left></span>
                        </template>
                        <template #next-text>
                            <span><b-icon-chevron-right scale="1.3"></b-icon-chevron-right></span>
                        </template>
                        <template #last-text>
                            <span><b-icon-chevron-double-right scale="1.3"></b-icon-chevron-double-right></span>
                        </template>
                    </b-pagination>
                </b-col>
            </b-row>
            <b-row class="mt-4 justify-content-end mb-5">
                <b-col class="text-end">
                    <label class="label-title">Paginas a exportar</label>
                </b-col>
                <b-col class="text-start">
                    <b-form-input type="number" class="rounded-0" size="sm" v-model="pageTake" style="width: 60px"
                        @input="handleInputChange" />
                </b-col>
                <b-col>
                    <xlsx-workbook>
                        <xlsx-sheet :collection="sheet.data" v-for="sheet in sheets" :key="sheet.name"
                            :sheet-name="sheet.name" />
                        <xlsx-download>
                            <b-button :disabled="pageTake == 0"
                                style="background-color: #2aad79; border-color: #2aad79">Exportar xlsx</b-button>
                        </xlsx-download>
                    </xlsx-workbook>
                </b-col>
                <b-col>
                    <b-button :disabled="pageTake == 0" style="background-color: #dc3545; border-color: #dc3545"
                        @click="generateReport" class="mb-3">Exportar pdf</b-button>
                </b-col>
            </b-row>
            <b-row class="mt-4 mb-5">
                <b-col>
                    <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false"
                        :paginate-elements-by-height="1400" filename="my-table" :pdf-quality="2" :manual-pagination="false"
                        pdf-format="a5" pdf-orientation="landscape" pdf-content-width="100%" ref="html2Pdf">
                        <section slot="pdf-content">
                            <!-- second usage of table component: this is for putting the contents of table to final pdf  -->
                            <b-table ref="eventsTablepdf" stacked="md" small hover select-mode="single" selectable
                                show-empty empty-text="No hay eventos que mostrar" :busy.sync="isBusy" :fields="fields"
                                :items="retrieveDataExport" :current-page="currentPage" :per-page="take * pageTake">
                                <template #table-busy>
                                    <div class="text-center text-primary my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                    </div>
                                    <div class="text-center text-primary my-2">
                                        <strong>Cargando eventos</strong>
                                    </div>
                                </template>
                            </b-table>
                        </section>
                    </vue-html2pdf>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import { eventTypes } from "@/config/enums";
import { RepositoryFactory } from "@/services/api";
import EventsFilters from "./EventsFilters.vue";
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
import VueHtml2pdf from 'vue-html2pdf';

export default {
    name: "events",
    components: {
        EventsFilters,
        XlsxDownload,
        XlsxWorkbook,
        XlsxSheet,
        VueHtml2pdf

    },
    data() {
        return {
            filters: null,
            fields: [
                { key: "creationDate", label: "Fecha de registro" },
                { key: "user.name", label: "Usuario" },
                { key: "user.userType", label: "Tipo de Usuario" },
                { key: "user.identification", label: "Identificación/Folio" },
                { key: "eventType", label: "Tipo de acción" },
                { key: "payload", label: "Detalles de evento" },
                { key: "sessionId", label: "Id session" },
            ],
            isBusy: false,
            currentPage: 1,
            take: 10,
            rows: 1,
            sheets: [{ name: "SheetOne", data: [{ c: 2 }] }],
            pdf: [],
            pageTake: 0,
            data: []
        };
    },
    methods: {
        filter(filters) {
            this.filters = filters;
            let table = this.$refs.eventsTable;

            if (table !== undefined) {
                table.refresh();
            }
        },
        async retrieveData(context) {
            this.isBusy = true;
            try {
                let payload = { ...this.filters };
                payload.page = context.currentPage;
                payload.take = 10;

                let response = await RepositoryFactory.event.getPagedEvents(
                    payload
                );

                this.rows = response.data.total;
                this.pageTake = response.data.pages;
                let items = response.data.items;

                // if (this.filters.userType !== null) {
                //     items = this.applyuserTypeFilter(items, parseInt(this.filters.userType));
                // }

                items = items.map(this.parseItem);
                return items;
            } catch (error) {
                window.console.log(error);
            }
            this.isBusy = false;
        },
        async retrieveDataExport() {
            if (this.pageTake > 0) {
                try {
                    let payload = { ...this.filters };
                    payload.page = 1;
                    payload.take = this.take * this.pageTake;

                    let response = await RepositoryFactory.event.getPagedEvents(
                        payload
                    );

                    this.rows = response.data.total;
                    let items = response.data.items;
                    items = items.map(this.parseItem);

                    this.prepareexcel(items);

                    return items;
                } catch (error) {
                    window.console.log(error);
                }
            }
        },
        parseItem(item) {
            item.creationDate = this.parseAndConvertDate(item.creationDate);
            item.eventType = this.getEventTypeLabel(item.eventType);
            item.payload = this.parsePayload(item.payload);
            item.user.userType = this.getUserTypeLabel(item.user.userType);
            // item.user.identification = item.user.identification;
            return item;
        },
        applyuserTypeFilter(items, usertype) {
            const DataAux = [];
            items.forEach(function (item) {
                if (item.user.userType === usertype) {
                    DataAux.push(item);
                }
            })
            return DataAux;
        },
        parseAndConvertDate(date) {
            const currentDate = new Date(date)
            return moment(currentDate.setHours(currentDate.getHours() - 5)).format("DD/MM/YYYY hh:mm:ss A");
        },
        getEventTypeLabel(id) {
            let event = eventTypes.find((e) => e.value === id);
            return this.$t(`event-type.${event.key}`);
        },
        parsePayload(payload) {
            let expression = ''

            if (payload === null)
                return

            if (payload.toUpperCase().includes('GET') || payload.toUpperCase().includes('UPDATE')) {
                let letters = payload.toLowerCase().split(' ');
                letters.map((l) => {
                    console.log(l)
                    let translate = this.$t(`payload.${l}`)
                    expression += translate === '' || translate === 'payload.' ? ' ' : ` ${translate}`
                })
            }
            else {
                const translate = this.$t(`payload.${payload}`)
                expression = translate.includes('payload.') ? payload : translate
            }
            return expression.toUpperCase();
        },
        isGuidOrNumber(value) {
            const pattern = (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i)
            return pattern.test(value) || isNaN(value)
        },
        getUserTypeLabel(id) {
            switch (id) {
                case 0:
                    return this.$t("user-type.resident-agent");
                case 1:
                    return this.$t("user-type.superintendence");
                case 2:
                    return this.$t("user-type.official");
                case 3:
                    return this.$t("user-type.natural-person");
                case 4:
                    return this.$t("user-type.legal-person");
                case 5:
                    return this.$t("user-type.competent-authority");
            }
            return "";
        },
        generateReport() {
            this.$refs.html2Pdf.generatePdf();
            RepositoryFactory.event.add({
                code: 2004,
                details: "downlad file pdf events"
            });
        },
        async prepareexcel(items) {
            const DataAux = [];
            items.forEach(function (value) {
                var obj = { "Fecha de Creacion": value.creationDate, "Usuario": value.user.name, "Tipo de Usuario": value.user.userType, "Tipo de evento": value.eventType, "Payload": value.payload };
                DataAux.push(obj);
            });
            this.sheets[0].data = DataAux;
        },
        handleInputChange(value) {
            this.pageTake = value;
        },
    },
};
</script>